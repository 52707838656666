@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&family=Rubik:wght@500;700&family=Source+Sans+3:wght@400;600;700&display=swap");

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

body {
  background: whitesmoke;
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100, "GRAD" 0;
}
